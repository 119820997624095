import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/builder"
import { CoreFields } from "./fragment" // eslint-disable-line
import { CoreBlockFragment } from "../components/blocks/CoreBlock" // eslint-disable-line

class Post extends Component {
  render() {
    this.props.pageContext.pageTitle = this.props.data.wpPost.Meta.title
      ? this.props.data.wpPost.Meta.title
      : this.props.data.wpPost.title
    this.props.pageContext.date = this.props.data.wpPost.date
    this.props.pageContext.cats = this.props.data.wpPost.categories
    const blocks = buildBlocks(
      this.props.data.wpPost.blocks,
      {},
      this.props.pageContext
    )

    return (
      <Layout
        meta={this.props.data.wpPost.Meta}
        path={this.props.pageContext.pagePath}
        title={this.props.data.wpPost.title}
        themeOptions={this.props.data.wp.themeOptions}
        pageColour={this.props.data.wpPost.acfPageColour.pageColour}
      >
        {blocks}
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String) {
    wpPost(id: { eq: $id }) {
      id
      slug
      title
      date(formatString: "DD MMMM YYYY")
      categories {
        nodes {
          name
          slug
        }
      }
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      acfPageColour {
        pageColour
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Post
